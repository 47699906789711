import './Stub.scss';

import AssetStoreExperimentalIcon from 'app/assets/asset-store-experimental-icon.svg';

const Stub = () => {
  return (
    <div className="main category stub main--filters">
      <div className="stub__top-container">
        <img src={AssetStoreExperimentalIcon} alt="Asset Store Experimental" />
        <p>This was an experimental version of the UNIGINE Asset Store.</p>
      </div>

      <div className="stub__middle-container">
        <p>
          The latest production version of the platform containing add-ons
          <br />
          for UNIGINE SDK 2.17+ is available at{' '}
          <a href="https://store.unigine.com">store.unigine.com</a>.
        </p>

        <a className="stub__button stub__button--green" href="https://store.unigine.com">
          Visit UNIGINE Add-On Store
        </a>
      </div>

      <div className="stub__bottom-container">
        <p>
          <b>If you use UNIGINE SDK 2.16.1 or earlier</b> versions you should manage your assets
          <br />
          <span>only via the SDK Browser</span>.
        </p>

        <a className="stub__button stub__button--grey" href="uniginesdk://navigate">
          Open SDK Browser
        </a>
      </div>
    </div>
  );
};

export default Stub;
