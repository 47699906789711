import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import withAppProviders from './withAppProviders';
import { Auth } from './auth';

function App() {
  return (
    <React.StrictMode>
      <Auth>
        <BrowserRouter>
          <div className="App">
            <Layout />
          </div>
        </BrowserRouter>
      </Auth>
    </React.StrictMode>
  );
}

export default withAppProviders(App)();
