import './Layout.scss';

import { useContext } from 'react';
import { useRoutes } from 'react-router-dom';

import Footer from 'app/components/Footer/Footer';
import Header from 'app/components/Header/Header';

import AppContext from 'app/AppContext';
import routes from 'app/configs/routesConfig';

const Layout = ({ children }) => {
  const appContext = useContext(AppContext);
  const element = useRoutes(routes);

  return (
    <div className="layout">
      <Header />
      <main className="content-wrapper">{element}</main>
      <Footer />
    </div>
  );
};

export default Layout;
