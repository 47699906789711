import { createSlice } from '@reduxjs/toolkit';
import jwtService from 'app/services/jwtService';
import getUniqueState from 'app/utils/getUniqueState';

export const setUserData = (user) => async (dispatch, getState) => {
  dispatch(setUser(user));
};

export const logoutUser = () => async (dispatch, getState) => {
  const { user } = getState().auth;

  if (!user.userData.id) {
    return null;
  }

  jwtService.logout();

  return dispatch(userLoggedOut());
};

const initialState = {
  userState: getUniqueState(),
  userData: {
    company: null,
    email: 'example@unigine.com',
    id: null,
    name: null,
  },
};

const userSlice = createSlice({
  name: 'auth/user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.userData = action.payload;
    },
    userLoggedOut: (state, action) => initialState,
  },
  extraReducers: {},
});

export const { setUser, userLoggedOut } = userSlice.actions;

export const userSelector = ({ auth }) => auth.user;
export const userStateSelector = ({ auth }) => auth.user.userState;

export default userSlice.reducer;
