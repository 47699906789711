import { Provider } from 'react-redux';
import AppContext from './AppContext';
import routes from './configs/routesConfig';
import store from './store';

const withAppProviders = (Component) => (props) => {
  const WrapperComponent = () => (
    <AppContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        routes,
      }}
    >
      <Provider store={store}>
        <Component {...props} />
      </Provider>
    </AppContext.Provider>
  );

  return WrapperComponent;
};

export default withAppProviders;
