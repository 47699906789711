import './Header.scss';

import { Link } from 'react-router-dom';

import NavigationList from 'app/components/NavigationList/NavigationList';
import logo from 'app/assets/unigine-logo.svg';
import assetStoreLogo from 'app/assets/asset-store-logo-experimental.svg';

function Header() {
  return (
    <header className="header">
      <div className="content-container content-container--header">
        <div className="header__top-container">
          <div className="header__logo logo logo__container">
            <Link className="logo__link" to="/all">
              <img src={logo} alt="Unigine logo" className="logo__unigine" />
              <img src={assetStoreLogo} alt="Asset Store (Experimental)" />
            </Link>
          </div>

          <nav className="header__navigation navigation navigation--top">
            <NavigationList />
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;
