const NavigationList = () => {
  return (
    <ul className="navigation__list">
      <li className="navigation__item">
        <a className="navigation__link" href="https://unigine.com">
          about unigine
        </a>
      </li>

      <li className="navigation__item">
        <a
          className="navigation__link"
          href={`${process.env.REACT_APP_STORE_DEVELOPER_URL}/en/docs/latest`}
        >
          docs
        </a>
      </li>

      <li className="navigation__item">
        <a className="navigation__link" href={`${process.env.REACT_APP_STORE_DEVELOPER_URL}/forum`}>
          forum
        </a>
      </li>

      {/* <li className="navigation__item">
        <a className="navigation__link" href="https://developer.unigine.com/en/support">
          support
        </a>
      </li> */}
    </ul>
  );
};

export default NavigationList;
