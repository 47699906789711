/* eslint-disable camelcase */
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import EventEmitter from 'app/utils/EventEmitter';

class JwtService extends EventEmitter {
  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
            // if you ever get an unauthorized response, logout the user
            this.emit('onAutoLogout', 'Invalid access_token');
            this.constructor.setSession(null);
          }
          throw err;
        });
      }
    );
  };

  handleAuthentication = () => {
    const access_token = this.constructor.getAccessToken();

    if (!access_token) {
      this.emit('onNoAccessToken');

      return;
    }

    if (this.constructor.isAuthTokenValid(access_token)) {
      this.constructor.setSession(access_token);
      this.emit('onAutoLogin', true);
    } else {
      this.constructor.setSession(null);
      this.emit('onAutoLogout', 'access_token expired');
    }
  };

  signInWithCode = (code) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_STORE_API_URL}/token`,
        data: {
          code,
        },
      })
        .then((response) => {
          const [{ accessToken, user }] = response.data;
          if (accessToken) {
            this.constructor.setSession(accessToken);
            resolve(user);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          console.log('Error:');
          console.log(error);
          this.logout();
          reject(new Error('Failed to login with token.'));
        });
    });
  };

  signInWithToken = () => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: `${process.env.REACT_APP_STORE_API_URL}/users/current`,
      })
        .then((response) => {
          const user = response.data;
          if (user) {
            this.constructor.setSession(this.constructor.getAccessToken());
            resolve(user);
          } else {
            this.logout();
            reject(new Error('Failed to login with token.'));
          }
        })
        .catch((error) => {
          console.log('Error:');
          console.log(error);
          this.logout();
          reject(new Error('Failed to login with token.'));
        });
    });
  };

  static setSession = (access_token) => {
    if (access_token) {
      localStorage.setItem('jwt_access_token', access_token);
      axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    } else {
      localStorage.removeItem('jwt_access_token');
      delete axios.defaults.headers.common.Authorization;
    }
  };

  logout = () => {
    console.log('logout...');
    this.constructor.setSession(null);
  };

  static isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn('access token expired');
      return false;
    }

    return true;
  };

  static getAccessToken = () => {
    return window.localStorage.getItem('jwt_access_token');
  };
}

const instance = new JwtService();

export default instance;
