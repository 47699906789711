import { createSlice } from '@reduxjs/toolkit';

const categoriesSlice = createSlice({
  name: 'categories',
  initialState: [
    {
      id: null,
      name: 'All',
      slug: 'all',
    },
    {
      id: '996dd3e6-4f25-4242-901b-807cae1e9b8c',
      name: '3d models',
      slug: '3d_models',
    },
    {
      id: 'd2d3c61f-a1c3-48b2-a696-713c25c4f5ce',
      name: 'Textures & Materials',
      slug: 'textures_and_materials',
    },
    {
      id: '1dffebd9-9096-4d92-a566-58eb2e4d164d',
      name: 'Plugins',
      slug: 'plugins',
    },
    {
      id: '9401a8e2-5651-4582-addf-035a0cfb6703',
      name: 'VFX',
      slug: 'vfx',
    },
    {
      id: 'a4ce7e51-9a54-4a32-80d3-a595944afbf9',
      name: 'Templates',
      slug: 'templates',
    },
    {
      id: 'fdbaa861-9b53-4064-912f-058e7bd7aed8',
      name: 'Tutorials',
      slug: 'tutorials',
    },
  ],
});

export default categoriesSlice.reducer;

export const categoriesSelector = ({ unigine }) => unigine.categories;
