import { Navigate } from 'react-router-dom';

import Stub from 'app/main/sections/Stub/Stub';

const routes = [
  {
    path: '',
    element: <Stub />,
  },
  {
    path: '*',
    element: <Navigate to="/" />,
  },
];

export default routes;
