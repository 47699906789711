import './Footer.scss';

import { Link } from 'react-router-dom';

import moment from 'moment';

import logo from 'app/assets/unigine-logo-white.svg';
import twitterLogo from 'app/assets/twitter.svg';
import facebookLogo from 'app/assets/facebook.svg';
import linkedinLogo from 'app/assets/linkedin.svg';
import youtubeLogo from 'app/assets/youtube.svg';
import discordLogo from 'app/assets/discord.svg';
import instagramLogo from 'app/assets/instagram.svg';

function Footer() {
  return (
    <footer className="footer">
      <div className="content-container content-container--footer">
        <div className="footer__top-container">
          <div className="logo logo__container">
            <Link className="logo__link" to="/">
              <img src={logo} alt="unigine-logo" className="logo-footer" />
            </Link>
          </div>
          <nav className="footer__navigation navigation navigation--top">
            <ul className="navigation__list">
              <li className="navigation__item-footer">
                <a className="navigation__link-footer" href="https://unigine.com">
                  About UNIGINE
                </a>
              </li>

              <li className="navigation__item-footer">
                <a
                  className="navigation__link-footer"
                  href={`${process.env.REACT_APP_STORE_DEVELOPER_URL}/en/docs/latest`}
                >
                  Docs
                </a>
              </li>

              <li className="navigation__item-footer">
                <a
                  className="navigation__link-footer"
                  href={`${process.env.REACT_APP_STORE_DEVELOPER_URL}/forum`}
                >
                  Forum
                </a>
              </li>

              {/* <li className="navigation__item-footer">
                <a
                  className="navigation__link-footer"
                  href={`${process.env.REACT_APP_STORE_DEVELOPER_URL}/en/support`}
                >
                  Support
                </a>
              </li> */}

              {/* <li className="navigation__item-footer">
                <a href="/tos" className="navigation__link-footer">
                  Terms of service
                </a>
              </li> */}
            </ul>
          </nav>
        </div>
        <hr className="footer__line" />
      </div>
      <div className="content-container content-container--footer">
        <div className="footer__bottom-container">
          <nav className="footer__navigation navigation navigation--top">
            <ul className="navigation__list">
              <div className="footer__channels-text">Our social channels</div>

              <li className="navigation__item-footer_bottom">
                <a className="navigation__link-footer" href="https://twitter.com/Unigine">
                  <img src={twitterLogo} alt="unigine-logo" className="logo" />
                </a>
              </li>

              <li className="navigation__item-footer_bottom">
                <a className="navigation__link-footer" href="https://www.facebook.com/Unigine">
                  <img src={facebookLogo} alt="unigine-logo" className="logo" />
                </a>
              </li>

              <li className="navigation__item-footer_bottom">
                <a
                  className="navigation__link-footer"
                  href="https://www.linkedin.com/company/unigine-corp-"
                >
                  <img src={linkedinLogo} alt="unigine-logo" className="logo" />
                </a>
              </li>

              <li className="navigation__item-footer_bottom">
                <a
                  className="navigation__link-footer"
                  href="https://www.youtube.com/unigine_official"
                >
                  <img src={youtubeLogo} alt="unigine-logo" className="logo" />
                </a>
              </li>

              <li className="navigation__item-footer_bottom">
                <a className="navigation__link-footer" href="https://discord.com/invite/gFN7w9b">
                  <img src={discordLogo} alt="unigine-logo" className="logo" />
                </a>
              </li>

              <li className="navigation__item-footer_bottom">
                <a
                  className="navigation__link-footer"
                  href="https://www.instagram.com/unigine_engine"
                >
                  <img src={instagramLogo} alt="unigine-logo" className="logo" />
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div className="unigine-rights">
        UNIGINE © 2005-{moment().year()}. All rights reserved. Legal Notice: All trademarks are the
        properties of their respective owners.
      </div>
    </footer>
  );
}

export default Footer;
