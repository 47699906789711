import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';

import jwtService from 'app/services/jwtService';

import { setUserData, logoutUser } from './store/userSlice';

class Auth extends Component {
  state = {
    waitAuthCheck: true,
  };

  componentDidMount() {
    return Promise.all([this.jwtCheck()])
      .then(() => {
        this.setState({ waitAuthCheck: false });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  jwtCheck = () =>
    new Promise((resolve) => {
      jwtService.on('onAutoLogin', () => {
        jwtService
          .signInWithToken()
          .then((user) => {
            this.props.setUserData(user);

            resolve();
          })
          .catch((error) => {
            console.log('jwtCheck error');
            console.log(error);

            resolve();
          });
      });

      jwtService.on('onAutoLogout', (message) => {
        console.log('onAutoLogout');
        if (message) {
          console.log(message);
        }

        this.props.logout();

        resolve();
      });

      jwtService.on('onNoAccessToken', () => {
        resolve();
      });

      jwtService.init();

      // eslint-disable-next-line no-promise-executor-return
      return Promise.resolve();
    });

  render() {
    return this.state.waitAuthCheck ? this.props.children : this.props.children;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout: logoutUser,
      setUserData,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(Auth);
